<template>
  <div>
    <v-container fluid fill-height>
      <c-dashboard-nav></c-dashboard-nav>

      <v-row class="align-self-center my-6 formCenter">
        <v-col>
          <v-card flat class="text-center">
            <div v-if="myEcosystems.length > 0">
              <div class="mt-4 mb-4">
                <h1 style="font-size: 3rem" data-cy="page-heading">
                  <span style="font-weight: 200" data-cy="heading">
                    {{ $t('dashboard.gatherings') }}
                  </span>
                  <span class="font-weight-bold" data-cy="subHeading">
                    {{ $t('dashboard.administration') }}
                  </span>
                </h1>
              </div>
              <div
                class="font-weight-light mb-10"
                data-cy="selectYourEcosystemOrCreateANewOne"
                style="font-size: 18px"
              >
                {{ $t('dashboard.selectYourEcosystemOrCreateANewOne') }}
              </div>
              <div v-for="ecosystem in myEcosystems" :key="ecosystem.id">
                <router-link
                  class="ecosystem-btn justify-space-between noUnderline hover-primary"
                  :to="{ path: `/ws/${ecosystem.id}` }"
                >
                  <div class="ecosystem-name hover-white-text">
                    {{ ecosystem.name }}
                  </div>
                  <v-icon size="16" class="hover-white-text chevron">fa-chevron-right</v-icon>
                </router-link>
              </div>
            </div>
            <div v-else class="union-shape mb-10 d-flex justify-center">
              <c-union style="margin-left: 100px"></c-union>
            </div>
            <v-btn
              type="submit"
              x-large
              @click.prevent="$router.push('/ecosystems/create')"
              color="primary"
              rounded
              class="create-btn-text create-btn mt-4"
              data-cy="create-ecosystem-button"
            >
              <v-icon size="16" style="margin-right: 10px">fa-plus-circle</v-icon>
              {{ $t('ecosystems.create.button') }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss" scoped>
.create-btn-text {
  text-transform: none;
  font-size: 16px;
  font-weight: 200 !important;
}
.create-btn {
  padding: 10px 20px 10px 20px;
}
.ecosystem-btn {
  margin: auto;
  display: flex;
  padding: 24px 32px;
  margin-bottom: 8px;
  max-width: 700px;
  background: #ffffff;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(128, 126, 240, 0.1);
  border-radius: 20px;
}
.ecosystem-name {
  font-family: Kiro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #363332;
}
.union-shape {
  margin-top: 60px;
}
.hover-primary:hover {
  background-color: #807ef0;
}
.hover-primary:hover .hover-white-text {
  color: white;
}
.chevron {
  margin-right: 10px;
  transition: none;
}
</style>
<script>
export default {
  computed: {
    myEcosystems() {
      return this.$store.getters['ecosystems/myEcosystems']
    },
  },
}
</script>
